<template>
    <section class="pb-5">
         <v-container grid-list-xl>
             <v-layout align-center row wrap>
                 <v-flex xs12>
                     <v-card class="elevation-0">
                         <v-card-content class="justify-center">
                             <iframe allowfullscreen frameborder="0" height="300" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3273.2796658865987!2d-82.37073328476309!3d34.87432298039027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88582e12e602c2bb%3A0xc9f329f85692945d!2s1541+Wade+Hampton+Blvd%2C+Greenville%2C+SC+29609!5e0!3m2!1sen!2sus!4v1543000918688" style="border:0" width="100%"></iframe>
                         </v-card-content>
                         <v-card-text class="grey--text  text--darken-2 font-weight-light">
                             <v-list>
                                 <v-list-tile-content class="ma-2"><b>YUMAPOS USA Corporate</b></v-list-tile-content>
                                 <v-list-tile-content class="ma-2">1235 East Blvd Ste-E 2025 Charlotte, NC 28203</v-list-tile-content>
                                 <v-list-tile-content class="ma-2"><b>(803) 932-8060</b></v-list-tile-content>
                             </v-list>
                         </v-card-text>
                     </v-card>
                 </v-flex>
             </v-layout>
         </v-container>
    </section>
</template>
<script>
  export default {
    name: "SupportForm"
  };
</script>

<style scoped>

</style>
