<template>
    <section class="white pb-5 pt-5">
        <v-container grid-list-md light text-xs-center>
            <v-layout align-center row wrap>
                <v-flex xs12>
                    <div class="text-xs-center ma-5 pt-5 pb-5" align="center">
                        <div class="flex display-1 font-weight-light pb-3">Manage Your Business More Efficiently
                        </div>
                      <div class="flex subheading font-weight-light">
                        Running a successful business poses challenges, from tasks like inventory management, reporting, and ensuring top-notch customer service to the daily responsibilities of updating menus, taking orders, and managing payments.
                      </div></div>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="/img/restaurant/pos-dashboard-business.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Restaurant Dashboard</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Manage every aspect of your restaurant through a single dashboard. Easily update your menu, promotions, pricing, and more.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="digital strategies" contain src="/img/restaurant/restaurant-dashboard.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Sales Reports & Data</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Engage your customers with email notifications, text messaging, rewards & loyalty, push notifications and more.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Digital Marketing Tools" contain src="/img/restaurant/reports-pos-best.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Customer Activity & Analytics</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Gain valuable insights into consumer data including buying habits, frequency, purchasing volume and other KPIs.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Restaurant Branding" contain src="img/restaurant/restaurant-mobile-apps-ri.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Real-Time Sync & Updates</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Ensure your customers have your latest restaurant menu. Update your restaurant menu once, and sync across all devices.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Restaurant Branding" contain src="img/restaurant/restaurant-send-promotional-offers.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Push Notifications & SMS</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Send notifications of upcoming events, specials, promotions, and invitations via email, push notifications and SMS messaging.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Digital Marketing Tools" contain src="img/restaurant/email-marketing-restaurant-local.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Email Marketing & Campaigns</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Create email campaigns to encourage repeat visits, broadcast special events, promote daily specials and more.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Mobile Apps POS" contain src="img/restaurant/loyalty-pos-apps.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Customer Loyalty & Incentives </v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Reward loyal customers with loyalty points. Track everything including purchases, loyalty points, redemptions, and more.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Website POS" contain src="img/restaurant/restaurant-loyalty-program.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Digital Gift Cards</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Take back control of gift card purchases to entice customers to purchase gift cards online without the need for a 3rd party.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Restaurant Branding" contain src="img/restaurant/mobile-pos-apps.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Notifcations & Messaging</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Send customer notifications and text messages instantly to encourage repeat visits and keep your customers tuned in.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Mobile Apps POS" contain src="img/restaurant/online-ordering-restaurant-web-design.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Menu & Daily Specials</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">Create products with types, attributes, and modifiers. Define different product types and prices for each location.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Restaurant Branding" contain src="img/restaurant/online-ordering-terminal-restaurant.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Sales & Order Management</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">Manage takeout and delivery orders through a centralized digital ordering system allowing for more efficient management.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Website POS" contain src="img/restaurant/restaurant-mobile-apps-ri.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Dine-In, Delivery & Take-Out</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customers can browse your menu items and schedule orders for dine-in, takeout and/or delivery from the location of choice.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Website POS" contain src="img/restaurant/order-history-restaurants.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Order Ahead & Order History</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customers can schedule takeout and delivery orders from the location of choice and place reorders using order history.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Restaurant Branding" contain src="img/restaurant/payment-processing-restaurant.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Payments & Processing</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customer payments are deposited directly into your bank account in real-time. with no commission fees or hidden fees.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Mobile Apps POS" contain src="img/restaurant/delivery-restaurant.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Delivery Zones & Tracking</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customers can place delivery orders based on users current location. Create delivery zones and track all deliveries.
                            \                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Restaurant Branding" contain src="img/restaurant/restaurant-menu-update-web.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Product List & Updates</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Update your menu's items and pricing from the backoffice and your mobile & web apps automatically update as well.
                        </v-card-text>
                    </v-card>
                </v-flex>
    
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Website POS" contain src="img/restaurant/restaurant-analytics.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Analytics & Reporting</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Access over 100+ reports and gain insights into every level of your customer analytics across all  locations.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Restaurant Branding" contain src="img/restaurant/menu-upates-restaurant.jpg"></v-img>
                        <v-card-title class="justify-center">Multi-Unit Support</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customers can place orders from multiple locations with the same account and provide feedback of their recent visit.
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
  export default {
    name: "RestaurantPos1.vue"
  };
</script>

<style scoped>

</style>
