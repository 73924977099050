<template>
    <section class="pb-5 pt-5">
        <v-container grid-list-md light text-xs-center>
        <v-layout align-center row wrap>
                <v-flex xs12>
                    <div class="text-xs-center ma-5 pt-5 pb-5" align="center">
                        <div class="flex display-1 font-weight-light pb-3">Custom Branded Web & Mobile Apps
                        </div>
                        <div class="flex subheading font-weight-light">
                            Custom branded web app that is integrated with online ordering and is fully integrated into the in-store POS system. Update your products, prices, and product descriptions in one place and sync across all locations and sales channels.
                        </div>
                    </div>
                </v-flex>
                <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain max-height="300px" src="/img/img/mobile-apps-for-restaurants.png">
                        </v-img>
                        <v-card-title class="justify-center">Restaurant Menu</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Your in-store restaurant menu synced with your IOS and Android mobile applications ensures your online customers always have your latest restaurant menu.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain max-height="300px" src="/img/img/restaurant-order-ahead.png">
                        </v-img>
                        <v-card-title class="justify-center">Order Ahead & Pre-Payment</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Your customers can order ahead and prepay for their orders upfront. Improve your customer's buying experience with mobile apps.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain max-height="300px" src="/img/img/retaurant-delivery-pos-en.png">
                        </v-img>
                        <v-card-title class="justify-center">In-Store, Delivery & Take-Out</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Rather in-store, delivery or takeout, your customers can place their order online using your branded mobile application.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain max-height="300px"  src="/img/img/restaurant-mobile-payments.png">
                        </v-img>
                        <v-card-title class="justify-center">Mobile Payments & Processing</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Your customers won’t need their wallets anymore. Customers can enter payment details on the app or use previously used payment methods to place new orders.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain max-height="300px"  src="/img/img/promo-discounts-loyalty.png">
                        </v-img>
                        <v-card-title class="justify-center">Promos, Notifications & Discounts</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Engage your customers with push notifications, TXT messaging, and email campaigns that encourage repeat visits, special events, daily specials and more.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain max-height="300px"  src="/img/img/restaurant-orders-history.png">
                        </v-img>
                        <v-card-title class="justify-center">Customer Loyalty</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Reward your loyal customers with loyalty points and rewards. The mobile app tracks everything including customer purchases, loyalty points, and redemptions.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md4>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain max-height="300px"  src="/img/img/multi-unit-restaurants.png">
                        </v-img>
                        <v-card-title class="justify-center">Multi-Unit Restaurants</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customers can select from any of your locations and place orders through a single mobile app. Multi-units makes it easy for customers to order from any of your locations without the need to install additional apps.
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
  export default {
    name: "WebView"
  };
</script>

<style scoped>

</style>
