<template>
    <section class=" pt-5">
        <v-container grid-list-md light text-xs-center>
            <v-layout align-center row wrap>
                <v-flex xs12>
                    <div class="text-xs-center ma-5 pt-5 pb-5" align="center">
                        <div class="flex display-1 font-weight-light pb-3">Y2POS USA
                        </div>
                        <div class="flex subheading font-weight-light">
                          Experience the ultimate all-in-one cloud-based POS system, finely tuned for hospitality and retail. Seamlessly synchronized across all locations, devices, and channels, it empowers you to efficiently manage your shop with unparalleled ease.
                          Give us a call at (803) 933-8060</div>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </section>

</template>

<script>
  export default {
    name: "SupportOne"
  };
</script>

<style scoped>

</style>
